import React from "react";
import Cards from "./Cards";

const RecentTravelogues = ({ travelogues }) => {
    return (
        <div className="pt-6">
          {travelogues &&
            travelogues.map((item, index) =>
              item && item.length > 0 ? (
                <Cards key={index} items={item} heading="Recent travel logs" />
              ) : (
                <div className="container">No travel logs found.</div>
              )
            )}
        </div>
    )
}

export default RecentTravelogues
