import React from "react";
import Cards from "./Cards";

const RecentBlogPosts = ({ blogPosts }) => {
    return (
        <div className="pb-6">
          {blogPosts &&
            blogPosts.map((item, index) =>
              item && item.length > 0 ? (
                <Cards key={index} items={item} heading="Recent blog posts" />
              ) : (
                <div className="container">No blog posts found.</div>
              )
            )}
        </div>
    )
}

export default RecentBlogPosts;