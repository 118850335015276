import React from "react"
import { Provider } from "../Context"
import { graphql } from "gatsby"
import Layout from "../layouts/Layout"
import RecentBlogPosts from "../components/RecentBlogPosts"
import RecentTravelogues from "../components/RecentTravelogues"
// import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => {
  const blogNodes = [data.blog.nodes]
  const travelLogNodes = [data.portfolio.nodes]
  const config = {
    showImagesOnCard :data.site.meta.showImagesOnCard
  }

  return (
    <Layout>
      <SiteMetadata
        title="Home"
        description="Blog and Portfolio of Kranthi Lakum"
      />

      <div className="bg-gray-100 py-12 lg:py-16">
        <Provider value={config}>
          <RecentBlogPosts blogPosts={blogNodes} />
          {data.site.meta.showTravelogueOnHome && <RecentTravelogues travelogues={travelLogNodes} />}
        </Provider>
      </div>
      {/* <Newsletter /> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    site {
      meta: siteMetadata {
        features {
          showImagesOnCard
          showTravelogueOnHome
        }
      }
    }
    portfolio: allContentfulPortfolio(limit: 5,
      sort: {
        order: DESC,
        fields: createdAt
      }) {
      nodes {
        ...PortfolioCard
      }
    }
    blog: allContentfulBlogPost(limit: 5,
      sort: {
        order: DESC,
        fields: publishDate
      }) {
      nodes {
        ...BlogPostCard
      }
    }
    hero: allContentfulPerson {
      nodes {
        shortBio {
          shortBio
        }
        name
        email
        github
        facebook
        instagram
        twitter
        company
      }
    }
  }
`
